import { useContext, useState } from "react";
import { BsTelephone, BsLock, BsX, BsPersonCircle, BsEnvelope } from "react-icons/bs";
import axiosClient from "../api/api";
import { PrimaryContext } from "../components/MainContext/MainContext";
import { errorToast } from "../adminPanel/components/toastAlerts/ToastAlerts";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const Authentication = ({ popupSetter }) => {
    const { t } = useTranslation()
    const [type, setType] = useState("registation");
    const [credentials, setCredentials] = useState({})
    const [loginCredentials, setLoginCredentials] = useState({})
    const { setToken, setUser, setIsAuthorized } = useContext(PrimaryContext);

    const handleRegister = () => {
        axiosClient.post("/signup", credentials)
            .then(({ data }) => {
                setToken(data.token)
                setUser(data.user)
                setIsAuthorized(true)
            })
            .catch(() => {
                errorToast(t("invalidData"))
            })
    }

    const handleLogin = () => {
        axiosClient.post("/login", loginCredentials)
            .then(({ data }) => {
                setToken(data.token)
                setUser(data.user)
                setIsAuthorized(true)
                popupSetter(false)
            })
            .catch(() => {
                errorToast(t("passwordMailIncorrect"))
            })
    }

    return (
        <div className="w-screen h-screen fixed top-0 left-0 z-[500] bg-black bg-opacity-80
        flex items-center justify-center">

            <div className="rounded-[30px] bg-white border border-slate-300 shadow-sm p-10 w-6/12 max-md:w-9/12 max-sm:w-11/12 relative">
                <BsX className="absolute top-5 right-5 w-6 h-6 text-red-500 cursor-pointer"
                    onClick={() => { popupSetter(false) }}
                />
                {type === "registation" &&
                    <div className="w-full">
                        <p className="w-full text-slate-500 text-xl text-center">{t("registration")}</p>
                        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 max-sm:gap-2 mt-5 max-sm:mt-2">
                            <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full
                            rounded-lg">
                                <BsPersonCircle className="text-slate-500 w-4 h-4" />
                                <input
                                    type="text"
                                    placeholder={t("name")}
                                    onChange={(e) => { setCredentials(prev => ({ ...prev, fname: e.target.value })) }}
                                    className="border-0 outline-none text-slate-500 w-full"
                                />
                            </div>
                            <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full
                            rounded-lg">
                                <BsPersonCircle className="text-slate-500 w-4 h-4" />
                                <input
                                    type="text"
                                    placeholder={t("surname")}
                                    onChange={(e) => { setCredentials(prev => ({ ...prev, lname: e.target.value })) }}
                                    className="border-0 outline-none text-slate-500 w-full"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 max-sm:gap-2 mt-5 max-sm:mt-2">
                            <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full 
                rounded-lg">
                                <BsTelephone className="text-slate-500 w-4 h-4" />
                                <input
                                    type="text"
                                    placeholder={t("number")}
                                    onChange={(e) => { setCredentials(prev => ({ ...prev, phone: e.target.value })) }}
                                    className="border-0 outline-none text-slate-500 w-full"
                                />
                            </div>
                            <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full 
                rounded-lg">
                                <BsEnvelope className="text-slate-500 w-4 h-4" />
                                <input
                                    type="text"
                                    placeholder={t("email")}
                                    onChange={(e) => { setCredentials(prev => ({ ...prev, email: e.target.value })) }}
                                    className="border-0 outline-none text-slate-500 w-full"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 max-sm:gap-2 mt-5 max-sm:mt-2">
                            <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full
                rounded-lg">
                                <BsLock className="text-slate-500 w-4 h-4" />
                                <input
                                    type="password"
                                    placeholder={t("password")}
                                    onChange={(e) => { setCredentials(prev => ({ ...prev, password: e.target.value })) }}
                                    className="border-0 outline-none text-slate-500 w-full"
                                />
                            </div>
                            <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm w-full
                rounded-lg">
                                <BsLock className="text-slate-500 w-4 h-4" />
                                <input
                                    type="password"
                                    placeholder={t("rePassword")}
                                    onChange={(e) => { setCredentials(prev => ({ ...prev, password_confirmation: e.target.value })) }}
                                    className="border-0 outline-none text-slate-500 w-full"
                                />
                            </div>
                        </div>
                        <button className="mt-5 max-sm:mt-2 rounded-lg bg-green-400 text-white tet-md w-full text-center p-3"
                            onClick={handleRegister}>
                            {t("registration")}
                        </button>
                        <p className="w-full text-slate-500 text-md text-center mt-5 max-sm:mt-2">{t("haveAccount")} ?
                            <span className="text-green-500 font-bold cursor-pointer"
                                onClick={() => { setType("authorization") }}
                            >  {t("authorization")}</span>
                        </p>
                    </div>
                }
                {type === "authorization" &&
                    <div className="w-full">
                        <p className="w-full text-slate-500 text-xl text-center">{t("authorization")}</p>
                        <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm mt-5 max-sm:mt-2 w-full 
                rounded-lg">
                            <BsEnvelope className="text-slate-500 w-4 h-4" />
                            <input
                                type="text"
                                placeholder={t("email")}
                                onChange={(e) => { setLoginCredentials(prev => ({ ...prev, email: e.target.value })) }}
                                className="border-0 outline-none text-slate-500"
                            />
                        </div>
                        <div className="flex items-center gap-2 p-2 border border-slate-300 shadow-sm mt-5 max-sm:mt-2 w-full
                rounded-lg">
                            <BsLock className="text-slate-500 w-4 h-4" />
                            <input
                                type="password"
                                placeholder={t("password")}
                                onChange={(e) => { setLoginCredentials(prev => ({ ...prev, password: e.target.value })) }}
                                className="border-0 outline-none text-slate-500"
                            />
                        </div>
                        <button className="mt-5 max-sm:mt-2 rounded-lg bg-green-400 text-white tet-md w-full text-center p-3"
                            onClick={handleLogin}>
                            {t("authorization")}
                        </button>
                        <p className="w-full text-slate-500 text-md text-center mt-5 max-sm:mt-2">{t("doNotHaveAccount")} ?
                            <span className="text-green-500 font-bold cursor-pointer"
                                onClick={() => { setType("registation") }}
                            > {t("registration")}</span>
                        </p>
                    </div>
                }
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div >
    )
}

export default Authentication