import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import { errorToast } from "../../toastAlerts/ToastAlerts";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { BsEnvelope, BsKey } from "react-icons/bs";

const LoginRihtSide = () => {
  const navigate = useNavigate();
  const { setToken, setUser, isAuthorized, setIsAuthorized, user } =
    useContext(AdminPrimaryContext);
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const handleLoginCredentials = (e) => {
    setLoginCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (isAuthorized) {
      navigate("../add-parents");
    }
  }, [isAuthorized]);

  const handleLogin = () => {
    axiosAdmin
      .post("/p/adminAuth/login", loginCredentials)
      .then(({ data }) => {
        setUser(data.admin);
        setToken(data.access_token);
        setIsAuthorized(true);
        navigate("../admin-home");
      })
      .catch((err) => {
        errorToast("პაროლი ან ელ-ფოსტა არასწორია!");
      });
  };

  function handlePressEnter(event) {
    if (event.key === "Enter") {
      handleLogin();
    }
  }

  return (
    <>
      <div
        className="w-5/12 max-w-[800px] bg-white  gap-[30px] p-16 max-lg:w-8/12 md:w-10/12
            sm:w-full sm:p-5 md:h-full"
      >
        <div className="mt-4 flex flex-col items-center justify-center">
          <h1 className="text-2xl font-semibold text-slate-600">Welcome</h1>
        </div>
        <div className="flex flex-col gap-5 mt-6">
          <div className="pv-2 h-10 flex items-center bg-slate-100 gap-x-1  px-3.5 py-1 rounded-md  group">
            <BsEnvelope className="text-gray-500 dark:text-white text-[18px]" />
            <input
              name="email"
              onChange={handleLoginCredentials}
              onKeyDown={handlePressEnter}
              className="w-full pl-1.5 focus:outline-none bg-slate-100 border-0"
              type="email"
              placeholder="email"
            />
          </div>
          <div className="pv-2 h-10 flex items-center bg-slate-100 group gap-x-1  px-3.5 py-1 rounded-md ">
            <BsKey className="text-gray-500 text-[18px]" />
            <input
              name="password"
              onChange={handleLoginCredentials}
              onKeyDown={handlePressEnter}
              className="w-full pl-1.5 focus:outline-none bg-slate-100 border-0"
              type="text"
              placeholder="password"
            ></input>
          </div>
          <div className="mt-6">
            <button
              onClick={handleLogin}
              className="w-full py-2  rounded-md bg-[#443cd3] hover:bg-[#4139c9]
                    text-white text-md text-center "
            >
              login
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default LoginRihtSide;
