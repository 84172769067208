import { useState, useEffect } from "react";
import UserCard from "./userCard";
import axiosAdmin from "../../../../api/apiAdmin";
import TextInput from "../../inputComponents/TextInput";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { BiUser } from "react-icons/bi";
import { BsEnvelope, BsLock } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const UserPageLayout = () => {
  const { t } = useTranslation();
  const [addNewToggler, setAddNewToggler] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [newAdmin, setNewAdmin] = useState({
    role: 1,
  });

  const handleNewAdminData = (value, inputName) => {
    setNewAdmin((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  const getData = () => {
    axiosAdmin
      .get(`/admin`)
      .then(({ data }) => {
        setAdminData(data);
      })
      .catch((err) => {});
  };

  const handleAddNewAdminToDb = () => {
    axiosAdmin
      .post(`/admin`, { ...newAdmin, role: "" })
      .then((res) => {
        getData()
        successToast("Success");
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  const handleDeleteAdmin = (id) => {
    axiosAdmin
      .delete(`/admin/${id}`)
      .then(({ data }) => {
        successToast("Success");
        getData();
      })
      .catch((err) => {
        errorToast("Error");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100">
      <div className="w-full pt-10 max-sm:p-5 p-10 flex flex-col gap-5">
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="rounded-lg p-3 text-white text-sm bg-violet-400 max-sm:w-full"
          >
            {t("addNewAdmin")}
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 w-full">
            <div className="w-full rounded-xl bg-white p-5">
              <div className="flex items-center justify-between flex-wrap gap-5 ">
                <div className="flex items-center gap-5">
                  <div>
                    <h2 className="text-xl text-slate-400 font-bold max-sm:text-sm">
                      {t("addNewAdmin")}
                    </h2>
                  </div>
                </div>
                <div className="flex gap-2 max-sm:w-full">
                  <button
                    onClick={handleAddNewAdminToDb}
                    className="rounded-lg p-3 text-white text-sm bg-violet-400 max-sm:w-full"
                  >
                    {t("save")}
                  </button>
                  <button
                    onClick={() => {
                      handleAddNewToggle(false);
                    }}
                    className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                  >
                    X
                  </button>
                </div>
              </div>
              <div className="pt-5 grid grid-cols-3 gap-4 max-md:grid-cols-1">
                <TextInput
                  name={t("name")}
                  Icon={BiUser}
                  inputSetter={handleNewAdminData}
                  inputName={"name"}
                />
                <TextInput
                  name={t("email")}
                  Icon={BsEnvelope}
                  inputSetter={handleNewAdminData}
                  inputName={"email"}
                />
                <TextInput
                  name={t("password")}
                  Icon={BsLock}
                  inputSetter={handleNewAdminData}
                  inputName={"password"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full min-h-screen">
        <div className="grid grid-cols-3 gap-4 p-10 pt-0 max-sm:p-5 max-sm:pt-0 justify-center max-md:grid-cols-1">
          {adminData &&
            adminData.map((obj) => {
              return (
                <UserCard
                  name={`${obj.name}`}
                  email={obj.email}
                  key={obj.id}
                  id={obj.id}
                  handleDeleteAdmin={handleDeleteAdmin}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UserPageLayout;
