import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SelectInputValueId from "../../inputComponents/SelectInputValueId";
import { FaRegSave } from "react-icons/fa";
import { useEffect, useState } from "react";
import useCRCData from "../../hooks/useCRCData";
import axiosAdmin from "../../../../api/apiAdmin";
import useLanguagesData from "../../hooks/useLanguagesData";
import TextEditor from "../../inputComponents/TextEditor";
import TextInput from "../../inputComponents/TextInput";
import { errorToast, successToast } from "../../toastAlerts/ToastAlerts";
import useEmailVariation from "../../hooks/useEmailVariation";
import usePrefixes from "../../hooks/usePrefixes";
import useLanguageVariations from "../../hooks/useLanguageVariations";
import UpdateServiceTable from "./UpdateServiceTable";
import useCurrencyData from "../../hooks/useCurrencyData";

const UpdateService = () => {
  const { t } = useTranslation();
  const { serviceId } = useParams();

  const [globalValues, setGlobalValues] = useState({});
  const [newData, setNewData] = useState({});
  const [service, setService] = useState({});
  const [currentCountryId, setCurrentCountryId] = useState(null);
  const [currentRegionId, setCurrentRegionId] = useState(null);
  const [lastCountries, setLastCountries] = useState([]);

  // custom hooks and data -------------------> //

  const { currency } = useCurrencyData();

  const { languages } = useLanguagesData();

  const countryData = useCRCData({ type: "country" });

  const regionData = useCRCData({
    type: "region",
    parent_id: currentCountryId,
    withFilters: true,
  });

  const { citiesData } = useCRCData({
    type: "city",
    parent_id: currentRegionId,
    withFilters: true,
    needGetName: true,
  });

  const { handleGetCityName, handleGetLanguageName, handleGetCurrencyName } =
    usePrefixes();

  const { handleEmailVariationsText, emailVariation } = useEmailVariation({
    currentEmail: globalValues?.crc_id,
    defaultValue: service?.serviceCrcs,
    retrieveCurrent: (value) => {
      handleNewData(value, "crcVariation");
    },
    parentId: serviceId,
    parentIdKey: "service_id",
    currency_id: globalValues?.currency_id,
  });

  const { handleLanguageVariationsText, languageVariation } =
    useLanguageVariations({
      currentLanguage: globalValues?.lang_id,
      defaultValue: service?.serviceLangs,
      retrieveCurrent: (value) => {
        handleNewData(value, "langVariation");
      },
      parentId: serviceId,
      parentIdKey: "service_id",
    });

  // custom hooks and data -------------------x //

  // state handlers -------------------> //

  const handleNewData = (value, key) => {
    setNewData((prev) => ({ ...prev, [key]: value }));
  };

  const handleGlobalValues = (value, key) => {
    setGlobalValues((prev) => ({ ...prev, [key]: value }));
  };

  // state handlers ------------------x //

  // apis -------------------> //

  const handleFetchData = () => {
    axiosAdmin
      .get(`/service/${serviceId}?relation=1`)
      .then(({ data }) => {
        setService(data);
        setNewData((prev) => ({
          ...prev,
          category_id: data.category_id,
          id: data.id,
          sort: data.sort,
          crcVariation: data.serviceCrcs,
          langVariation: data.serviceLangs,
        }));
      })
      .catch((err) => {});
  };

  const handleAddNewCRC = () => {
    axiosAdmin
      .get(`/service/createCrcVariation/${serviceId}/${globalValues.crc_id}`)
      .then((res) => {
        handleFetchData();
      })
      .catch((err) => {});
  };

  const handleAddNewLanguage = () => {
    axiosAdmin
      .get(`/service/createLangVariation/${serviceId}/${globalValues.lang_id}`)
      .then((res) => {
        handleFetchData();
      })
      .catch((err) => {});
  };

  const updateService = (payload, linkPart, id) => {
    if (id) {
      axiosAdmin
        .put(`/service/${linkPart}/${id}`, payload)
        .then((res) => {
          handleFetchData();
          successToast(t("Success"));
        })
        .catch((err) => {
          errorToast(t("Error"));
        });
    }
  };

  const handleGetLastCountries = () => {
    axiosAdmin
      .get("/category?last=1")
      .then(({ data }) => {
        setLastCountries(
          data.map((item) => {
            return {
              name: item?.categoryLangs?.length
                ? item?.sort + " - " + item?.categoryLangs[0].name
                : "",
              value: item?.id,
              id: item?.id,
            };
          })
        );
      })
      .catch((err) => {});
  };

  const handleAddTable = (id) => {
    axiosAdmin
      .get(`/service/addTable/?service_id=${serviceId}&table_id=${id}`)
      .then((res) => {
        handleFetchData();
      })
      .catch((err) => {});
  };

  const handleDeleteTable = (id) => {
    axiosAdmin
      .delete(`/service/deleteServiceTable/${id}`)
      .then((res) => {
        handleFetchData();
      })
      .catch((err) => {});
  };

  // apis -------------------x //

  // effects -------------------> //

  useEffect(() => {
    handleFetchData();
    handleGetLastCountries();
  }, []);

  // effects -------------------X //

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0
    relative h-[93.5vh] scrollbar overflow-y-auto pb-40"
    >
      {/* <div
        className=" w-full fixed px-10 top-[56px] p-2 bg-white border border-slate-400 z-40 grid grid-cols-2 gap-2
  max-lg:grid-cols-1"
      >
       
      </div> */}

      <div
        className=" w-full sticky px-10 top-[0] py-2 bg-white border border-slate-400 z-40 grid grid-cols-2 gap-2
  max-lg:grid-cols-1"
      >
        <div className="w-full flex gap-2 items-center">
          <SelectInputValueId
            placeholder={t("language")}
            options={languages}
            inputName={"lang_id"}
            inputSetter={(value, key) => {
              handleGlobalValues(value, key);
              handleNewData(value, key);
            }}
          />
          <button
            onClick={handleAddNewLanguage}
            className="w-[37px] h-[37px] p-2 bg-blue-400 rounded-lg cursor-pointer shrink-0"
          >
            <FaRegSave className="text-white h-full w-full" />
          </button>
        </div>
        <div className="w-full gap-2 flex items-center">
          <div className="w-full grid grid-cols-4 gap-2">
            <SelectInputValueId
              placeholder={t("country")}
              options={countryData}
              inputName={"crc_id"}
              inputSetter={(value, _) => {
                setCurrentCountryId(value);
              }}
            />
            <SelectInputValueId
              placeholder={t("region")}
              options={regionData}
              inputName={"crc_id"}
              inputSetter={(value, _) => {
                setCurrentRegionId(value);
              }}
            />

            <SelectInputValueId
              placeholder={t("city")}
              options={citiesData}
              inputName={"crc_id"}
              inputSetter={(value, key) => {
                handleGlobalValues(value, key);
                handleNewData(value, key);
              }}
            />
            <SelectInputValueId
              placeholder={t("currency")}
              options={currency}
              inputName={"currency_id"}
              inputSetter={(value, key) => {
                handleGlobalValues(value, key);
              }}
            />
          </div>
          <button
            onClick={handleAddNewCRC}
            className="w-[37px] h-[37px] p-2 bg-green-400 rounded-lg cursor-pointer shrink-0"
          >
            <FaRegSave className="text-white h-full w-full" />
          </button>
        </div>
      </div>

      <div className="w-full pt-20 px-10 pb-10 max-sm:px-5 max-sm:pb-5 flex flex-col gap-5 max-lg:pt-32">
        <div className="bg-slate-100 flex flex-col gap-5">
          <div className="w-full rounded-xl bg-white p-5 border border-slate-300 shadow-md ">
            <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 mt-4">
              <div
                tabIndex="0"
                onBlur={() => {
                  updateService(
                    {
                      id: newData?.id,
                      category_id: newData?.category_id,
                    },
                    "updateService",
                    serviceId
                  );
                }}
              >
                <SelectInputValueId
                  name={t("parent")}
                  options={lastCountries}
                  inputName={"category_id"}
                  value={service?.category_id}
                  inputSetter={handleNewData}
                />
              </div>
              <div
                tabIndex="0"
                onBlur={() => {
                  updateService(
                    {
                      id: newData?.id,
                      sort: parseFloat(newData?.sort),
                    },
                    "updateService",
                    serviceId
                  );
                }}
              >
                <TextInput
                  name={t("index")}
                  inputName={"sort"}
                  type="number"
                  value={parseInt(service?.sort)}
                  placeholder={t("index")}
                  inputSetter={handleNewData}
                />
              </div>
            </div>

            <div
              className="relative group mt-5"
              tabIndex="0"
              onBlur={() => {
                updateService(
                  {
                    id: newData?.crcVariation?.id,
                    currency_id: newData?.crcVariation?.currency_id,
                    mail: newData?.crcVariation?.mail,
                  },
                  "updateServiceCrc",
                  newData?.crcVariation?.id
                );
              }}
            >
              <TextInput
                name={t("email")}
                inputName={"mail"}
                value={service?.mail}
                placeholder={t("email")}
                inputSetter={handleEmailVariationsText}
              />
              {emailVariation?.length ? (
                <div
                  className="bg-white rounded-lg p-3 
            border border-slate-300 gap-2 mt-2"
                >
                  {emailVariation.map((item) => {
                    return (
                      <div key={item.crc_id}>
                        <p className="text-sm text-gray-500 ">
                          {handleGetCityName(item.crc_id)}{" "}
                          {`(${handleGetCurrencyName(item.currency_id)})`}{" "}
                          {" - "}
                          {item.mail}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="relative mt-5"
              tabIndex="0"
              onBlur={() => {
                updateService(
                  {
                    id: newData?.langVariation?.id,
                    name: newData?.langVariation?.name,
                    description: newData?.langVariation?.description,
                  },
                  "updateServiceLang",
                  newData?.langVariation?.id
                );
              }}
            >
              <TextInput
                name={t("name")}
                inputName={"name"}
                placeholder={t("name")}
                inputSetter={handleLanguageVariationsText}
                value={newData?.langVariation?.name}
              />
              {languageVariation?.length ? (
                <div
                  className="bg-white rounded-lg p-3 
            border border-slate-300 gap-2 mt-2"
                >
                  {languageVariation.map((item) => {
                    return (
                      <div key={item.lang_id}>
                        <p className="text-sm text-gray-500 ">
                          {handleGetLanguageName(item.lang_id)} {" - "}
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="relative mt-5"
              tabIndex="0"
              onBlur={(event) => {
                const currentTarget = event.currentTarget;
                // Check if focus is still within the component
                setTimeout(() => {
                  const activeElement = document.activeElement;
                  if (!currentTarget.contains(activeElement)) {
                    // Focus has moved outside, update row
                    if (newData?.langVariation?.description !== "") {
                      updateService(
                        {
                          id: newData?.langVariation?.id,
                          name: newData?.langVariation?.name,
                          description: newData?.langVariation?.description,
                        },
                        "updateServiceLang",
                        newData?.langVariation?.id
                      );
                    }
                  }
                }, 0);
              }}
            >
              <TextEditor
                name={"description"}
                inputSetter={handleLanguageVariationsText}
                value={newData?.langVariation?.description}
              />
              {languageVariation.length ? (
                <div
                  className="bg-white rounded-lg p-3 
            border border-slate-300 gap-2 mt-2"
                >
                  {languageVariation.map((item) => {
                    return (
                      <div key={item.lang_id} className="flex gap-2">
                        <p className="text-sm text-gray-500 shrink-0">
                          {handleGetLanguageName(item.lang_id)} {" - "}
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
            <div
              className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
              onClick={() => {
                handleAddTable(service?.serviceTables[0].id);
              }}
            >
              + {t("dropDown")}
            </div>
          </div>

          <div className="w-full">
            {service?.serviceTables?.map((item) => {
              return (
                <UpdateServiceTable
                  key={item.id}
                  item={item}
                  handleDeleteTable={handleDeleteTable}
                  handleAddTable={handleAddTable}
                  handleFetchData={handleFetchData}
                  parentLangId={
                    service?.serviceLangs?.filter((item) => {
                      return (
                        String(item.lang_id) ===
                        String(newData?.langVariation?.lang_id)
                      );
                    })?.length
                      ? service?.serviceLangs?.filter((item) => {
                          return (
                            String(item.lang_id) ===
                            String(newData?.langVariation?.lang_id)
                          );
                        })[0]?.id
                      : undefined
                  }
                  parentCrcId={
                    service?.serviceCrcs?.filter((item) => {
                      return (
                        String(item.crc_id) ===
                        String(newData?.crcVariation?.crc_id)
                      );
                    })?.length
                      ? service?.serviceCrcs?.filter((item) => {
                          return (
                            String(item.crc_id) ===
                            String(newData?.crcVariation?.crc_id)
                          );
                        })[0]?.id
                      : undefined
                  }
                  handleGetLanguageName={handleGetLanguageName}
                  handleGetCityName={handleGetCityName}
                />
              );
            })}
          </div>

          <div className="w-full rounded-xl bg-white p-5 mt-5 border border-slate-300 shadow-md flex items-center gap-2">
            <div
              className="rounded-lg p-3 text-white text-sm bg-green-400 cursor-pointer"
              onClick={() => {
                handleAddTable(
                  service?.serviceTables?.length === 0
                    ? 0
                    : service?.serviceTables[service?.serviceTables?.length - 1]
                        .id
                );
              }}
            >
              + {t("dropDown")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateService;
