import { useState, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axiosAdmin from "../../api/apiAdmin";
import { AdminPrimaryContext } from "./adminContext/AdminContext";

import { BsList, BsChevronLeft, BsDoorOpenFill } from "react-icons/bs";
import { BsPersonCircle } from "react-icons/bs";
import logo from "../../assets/img/phoneLogo.png";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/languageSwitcher/LanguageSwitcher";

function FullNavigation() {
  const navigate = useNavigate();
  const { setUser } = useContext(AdminPrimaryContext);
  const [sideBarLeftOpen, setSideBarLeftOpen] = useState(false);
  const { user, setIsAuthorized } = useContext(AdminPrimaryContext);
  const [toggleMenu, setToggleMenu] = useState(false);

  const logOut = async () => {
    try {
      const res = await axiosAdmin.get("/admin/logout");
      if (res) {
        setIsAuthorized(false);
        localStorage.removeItem("GADADI_TOKEN_ADM");
        setUser({});
        navigate("/admin/login");
      }
    } catch (err) {}
  };

  return (
    <>
      <section
        className="fixed bg-white top-0 left-0 w-full h-14 flex items-center justify-between z-50
                border-b border-solid border-neutral-300 py-3.5 px-10 md:px-4 sm:px-4 dark:bg-slate-800 dark:border-slate-600"
      >
        <div
          onClick={() => {
            setSideBarLeftOpen(!sideBarLeftOpen);
          }}
          className="flex items-center justify-center"
        >
          <div
            onClick={() => {
              setToggleMenu((prev) => !prev);
            }}
          >
            <BsList
              size={30}
              className="text-[#4f46e5] dark:text-white cursor-pointer"
            />
          </div>
        </div>
        <div className="flex items-center justify-center gap-2.5">
          <LanguageSwitcher />

          <div className="flex gap-2.5">
            <div
              onClick={logOut}
              className="flex items-center justify-center hover:rounded-full hover:bg-gray-100 w-8 h-8
                            cursor-pointer"
            >
              <BsDoorOpenFill
                size={18}
                className="text-gray-500 dark:text-white"
              />
            </div>
            <div
              className="flex items-center justify-center  bg-gray-100 h-8 dark:bg-slate-600
                            cursor-pointer px-2 rounded-lg gap-1"
            >
              <BsPersonCircle
                size={18}
                className="text-gray-500 dark:text-white"
              />
              <p className="text-gray-500 dark:text-white text-[12px]">{`${user.name}`}</p>
            </div>
          </div>
        </div>
      </section>
      <LeftSideToggleNavigation
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
      />
    </>
  );
}

const DropDownMenuItem = ({
  outerName,
  outerLink,
  setCurrentPage,
  currentPage,
}) => {
  return (
    <div>
      <Link
        to={outerLink}
        className={`w-60 p-2 rounded-lg block ${
          currentPage === outerLink && outerLink !== ""
            ? "bg-slate-300"
            : "hover:bg-slate-300"
        }`}
        onClick={() => {
          setCurrentPage(outerLink);
        }}
      >
        <div className="flex justify-between items-center">
          <p className="text-sm text-gray-500 font-bold dark:text-gray-400 ">
            {outerName}
          </p>
        </div>
      </Link>
    </div>
  );
};

function LeftSideToggleNavigation({ toggleMenu, setToggleMenu }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(() => {
    return location.pathname.split("/").pop();
  });

  return (
    <section
      className={`flex fixed top-0 left-0 z-50 transition-all
          ${toggleMenu ? "max-xl:translate-x-0" : " max-xl:translate-x-[-100%]"}
        `}
    >
      <div>
        <div
          className=" flex flex-col bg-white  dark:bg-slate-800
                    z-50 h-screen border-r border-neutral-30 py-2 px-5 dark:border-slate-600"
        >
          <div className="flex justify-between items-center border-b  border-neutral-30 pb-2 dark:border-slate-600">
            <div className="w-full flex justify-between items-center dark:border-slate-500">
              <div className="flex gap-2 items-center">
                <Link to={"/"}>
                  <div className="w-10 h-10">
                    <img
                      src={logo}
                      className="w-full h-full rounded-full"
                    ></img>
                  </div>
                </Link>
                <p className="indent-2 text-sm text-gray-500 font-bold dark:text-gray-400">
                  Gadadi CMS
                </p>
              </div>
              <div className="xl:hidden ">
                <BsChevronLeft
                  onClick={() => {
                    setToggleMenu((prev) => !prev);
                  }}
                  className="text-md text-gray-600 dark:text-gray-400 cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3.5 relative overflow-y-auto  scrollbar">
            <div className="flex flex-col gap-1 pr-2 ">
              <DropDownMenuItem
                outerName={t("addService")}
                outerLink={"add-parents"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <DropDownMenuItem
                outerName={t("serviceQueston")}
                outerLink={"service"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <DropDownMenuItem
                outerName={t("countries")}
                outerLink={"add-countries"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <DropDownMenuItem
                outerName={t("admins")}
                outerLink={"admins"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <DropDownMenuItem
                outerName={t("users")}
                outerLink={"user"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <DropDownMenuItem
                outerName={t("languages")}
                outerLink={"languages"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <DropDownMenuItem
                outerName={t("orders")}
                outerLink={"orders"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FullNavigation;
